'use client';

import React, {useContext, useEffect, useRef, useState} from 'react';
import { $displayAdStarted, AdProps, displayAdEmptyUpdated } from './model';
import { useUnit } from 'effector-react';
import { $arkadiumSdkStore } from '@/features/arkadiumsdk/model';
import {AnalyticsContext} from "@/components/Layout";

 const Ad = (props: AdProps) => {
    const { adOptions, className } = props;
    const [isClient, setIsClient] = useState(false);
    const sdk = useUnit($arkadiumSdkStore);
    const adRef = useRef<(HTMLElement & { coreService: any }) | null>(null);
    const adsEmptyHandler = useUnit(displayAdEmptyUpdated);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const {AITracks, AIReadyAsDep} = useContext(AnalyticsContext);
    useEffect(() => {
        setIsClient(true);
    }, []);
    // no dependency array because we check it in useEffect, workaround to get ref asap
    useEffect(() => {
        if (adRef?.current?.coreService && !isSubscribed) {
            adRef.current.coreService.onShowGTMEvent = (
                ads: string[],
                isRefresh: boolean,
                eventDetail: {
                    isEmpty: boolean;
                    size: Array<[number, number]>;
                    cpm: number;
                }
            ) => {
                ads.map((ad) => {
                    adsEmptyHandler({ id: ad, empty: eventDetail.isEmpty });
                });
            };
            setIsSubscribed(true);
        }
    });
    const adStarted = useUnit($displayAdStarted);

    useEffect(() => {
        adStarted && AITracks.displayAd(adRef?.current, false);
    }, [AIReadyAsDep, adStarted]);

    const id = props.adOptions['data-id'];
    if ((!adStarted || !isClient || !sdk) && id !== 'test-ad') {
        return null;
    }

    return AIReadyAsDep && adStarted && (
         <div data-testid="ad-container" className={className}>
            <div data-element-description={adOptions['data-id']} data-testid={'display-ad'}>
                {/* @ts-ignore */}
                <display-ad-component ref={adRef} {...adOptions} key={adOptions['data-id']} />
            </div>
        </div>
    );
};
Ad.displayName = 'Ad';

export default Ad;
