import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/app/[locale]/[...slug]/styles.module.css");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/components/HeadlineCardsGrid/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/components/HorizontalPills/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/components/Layout/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/components/Tags/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/components/TogglePanel/styles.module.css");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/features/ad/view.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/features/carousel/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/features/carouselPromo/index.tsx");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/features/category-page/styles.module.css");
import(/* webpackMode: "eager" */ "/var/vsts_agent/_work/266/s/src/features/iframe-block/index.tsx")